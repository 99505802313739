<template>
  <div>
    <h1>Cidades com taxa</h1>

    <v-btn
      color="error"
      @click="$router.push({ name: 'Cities.index' })"
    >Voltar</v-btn>

    <v-row>
      <v-col cols="12" md="2">
        <v-select
          :items="partners"
          item-text="name"
          item-value="id"
          label="Parceira"
          v-model="partner"
        ></v-select>

        <v-btn
          color="success"
          :disabled="cities.length < 1 || loading"
          @click="print"
        >
          <v-icon class="mr-2">mdi-printer</v-icon>
          Imprimir
        </v-btn>
      </v-col>
      <v-col cols="12" md="10">
        <v-data-table
          :loading="loading"
          :items="cities"
          disable-pagination
          hide-default-footer
          dense
          :headers="[
            {text: 'Cidade', value: 'name'},
            {text: 'TDA', value: 'tda'}
          ]"
          :item-class="() => 'cursor-pointer'"
          @click:row="handleClick"
        >
          <template v-slot:[`item.tda`]="{ item }">
            <span>{{ $functions.moneyFormat(item.city_fees[0].value) }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import partnersModule from '@/modules/partners';

  export default {
    watch: {
      partner() {
        this.getCities();
      }
    },
    mounted () {
      this.getPartners();
    },
    data() {
      return {
        partners: [],
        partner: null,
        cities: [],
        loading: false,
        partnersNamed: [],
      }
    },
    methods: {
      handleClick(event) {
        let routeData = this.$router.resolve({ name: 'Cities.edit', params: { id: event.id } });

        window.open(routeData.href, '_blank');
      },
      async getPartners() {
        this.partners = await partnersModule();
      },
      async getCities() {
        this.loading = true;
        try {
          let { data } = await this.$http.get(`api/cities?tda=true&partner=${this.partner}`);

          this.cities = data;

          this.cities.forEach(city => {
            let newFee = city.city_fees.filter(fee => {
              return fee.partner_id === this.partner;
            });

            city.city_fees = newFee;
          });

          let list = [];

          this.partners.forEach(partner => {
            list[partner.id] = partner.name;
          });

          this.partnersNamed = list;
        } catch (error) {
          console.log(error);

          this.$toast.error('Erro ao carregar cidades');
        }
        this.loading = false;
      },
      print() {
        let win = window.open('', '', `width=${screen.width}, height=${screen.height}`);
        win.document.title = `Cidades com TDA - ${ this.partnersNamed[this.partner] }`;
        win.document.write(`<h1 style="text-align: center">Cidades com TDA - ${ this.partnersNamed[this.partner] }</h1>`);

        win.document.write(`<table>`);
        win.document.write(`
          <thead>
            <tr>
              <th>Cidade</th>
              <th>TDA</th>
            </tr>
          </thead>
        `);
        win.document.write(`<tbody>`);
        this.cities.forEach(city => {
          win.document.write(`<tr>`);
            win.document.write(`<td>${ city.name }</td>`);
            win.document.write(`<td>${ this.$functions.moneyFormat(city.city_fees[0].value) }</td>`);
          win.document.write(`</tr>`);
        })
        win.document.write(`</tbody>`);
        win.document.write(`</table>`);

        win.document.write(`
          <style>
            table {
              width: 400px;
              margin: 0 auto;
              border-collapse: collapse;
            }

            th, td {
              text-align: center;
              border: solid 1px;
              margin: 0;
              padding: 0 5px;
            }
          </style>
        `);
      }
    },
  }
</script>